/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid';

export default function Alert({ children, type }) {
  let colors = {
    bg: 'bg-green-100',
    text: 'text-green-900',
  };

  if (type === 'warning') {
    colors = {
      bg: 'bg-yellow-100',
      text: 'text-yellow-900',
    };
  }

  if (type === 'danger') {
    colors = {
      bg: 'bg-red-100',
      text: 'text-red-900',
    };
  }

  if (type === 'info') {
    colors = {
      bg: 'bg-blue-100',
      text: 'text-blue-900',
    };
  }

  return (
    <div className={`p-4 rounded-md ${colors.bg}`}>
      <div className='flex'>
        <p className={`text-sm font-medium ${colors.text}`}>{children}</p>
      </div>
    </div>
  );
}
