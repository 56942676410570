import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { motion } from 'framer-motion';
import { ChevronLeftIcon } from '@heroicons/react/solid';

// components
import Container from './Container';

export default function PageHeader({ title, description, image, short, backHref }) {
  const [backButtonHovered, setBackButtonHovered] = useState(false);

  let paddingClasses = 'pt-48 pb-24';

  if (short) {
    paddingClasses = 'pt-48 pb-12 md:pt-32 md:pb-12';
  }

  return (
    <div className='bg-gradient-to-b from-primary-500 to-primary-600'>
      <Container className={'relative'}>
        <div className={`${paddingClasses} lg:flex lg:justify-between`}>
          <div className='max-w-sm lg:max-w-xl'>
            <h2 className='text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl'>{title}</h2>
            <p className='mt-5 text-2xl text-white text-opacity-50'>{description}</p>
            {backHref && (
              <div className='inline-flex mt-5'>
                <Link href={backHref}>
                  <a
                    className='flex items-center space-x-3 font-bold text-white transition-colors duration-200'
                    onMouseEnter={() => setBackButtonHovered(true)}
                    onMouseLeave={() => setBackButtonHovered(false)}
                  >
                    <motion.div className='w-6 -mb-[2px]' animate={{ x: backButtonHovered ? -5 : 0 }}>
                      <ChevronLeftIcon />
                    </motion.div>
                    <span>All Raffles</span>
                  </a>
                </Link>
              </div>
            )}
          </div>
        </div>
        {image && (
          <div className='absolute hidden md:block bottom-0 right-0 object-cover md:w-[300px] md:h-[300px] mb-[-6px] md:mb-0'>
            <Image src={image} alt='Heepow' width={500} height={500} />
          </div>
        )}
      </Container>
    </div>
  );
}
